.module-order {
  display: flex;
  margin: 22px 0;
  align-items: center;
  justify-content: space-between;
}

.module-order__button_wrapper {}

.module-order__button {
  margin: 0 3px;
}
