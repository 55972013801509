.sheet-modules-list {

  .sheet-modules-list_header {
    display: flex;
    margin: 0 0 15px;
    align-items: center;
  }

  .sheet-modules-list_header_new {
    margin: 0 15px;
  }


}