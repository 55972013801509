$tablet-width: 900px;
$mobile-width: 576px;

@font-face {
  font-family: 'Thempo';
  src: url('/font/Thempo_New_St.woff') format('woff'),
  url('/font/Thempo_New_St.tff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('/font/OpenSans-Light.woff') format('woff'),
  url('/font/OpenSans-Light.tff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  margin: 0;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.btn {
  cursor: pointer;
}

#js-error.hidden {
  display: none;
}
