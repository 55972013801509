.search-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  .btn {
    width: 37px;
    padding: 6px 0;
    text-align: center;
    margin: 0 2px;
    @media (min-width: $tablet-width) {
      width: 44px;
      padding: 6px 0;
    }
  }

  .btn-light {
    border-color: #dae0e5;
    cursor: pointer;
  }
}
