//roles list

.roles-table__permissions-list {
  width: 75%;
}

.roles-list td.text-center, .roles-list th.text-center {
  text-align: center;
}

.roles-list__buttons {
  display: flex;
  justify-content: center;

  .btn {
    margin: 0 1px;
  }
}

.roles-list__header {
  display: flex;
  align-items: center;

  .modal-button__create-asset {
    margin-left: 15px;
  }

}

//roles edit

.roles-edit__wrapper {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.roles-edit .btn {
  margin-right: 10px;
}

.roles-edit__legacy-permissions {
  padding: 19px;
  margin: 20px 0;
  background-color: #f5f5f5;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
}

.roles-edit__permissions {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.roles-edit__permissions-group {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 25%;

  &.roles-edit__all-roles {
    width: 75%
  }
}

.roles-edit__permissions__header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.roles-edit__permissions__header-available {
  width: 75%;
}

.roles-edit__permissions__header-role {
  width: 25%;
}

.roles-edit__permissions__column {
  padding-top: 10px;
  margin: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  width: 286px;

  &.fill-container {
    height: 100%;
  }

  &.fixed-height {
    max-height: 500px;
  }
}

.roles-edit__permissions__column-container {
  padding: 10px;
  flex-grow: 1;
  min-height: 100px;

  &.isDraggingOver {
    background-color: #C1C7D0;
  }
}

.roles-edit__permissions__column-header {
  text-align: center;
  margin: 0px;
}

.roles-edit__permissions__card {
  padding: 5px;
  margin: 5px;
  background-color: #ffffff;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  &.dnd_source__dragging {
    box-shadow: 2px 2px 1px #8993A4;
    background-color: #B3F5FF;
  }

  &.dnd_source__ghosting {
    opacity: 0.8;
  }

  &.dnd_source__selected {
    background-color: #B3F5FF;
  }
}

.roles-edit__permissions__card__remove {
  width: 20px;
  height: 20px;
  border: none;
  background-color: #FF991F;
  border-radius: 4px;
  margin-right: 6px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
