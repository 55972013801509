.react-modal_open {
  margin: 0 10px;
}

.react-modal_overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-modal {
  background: #fff;
  width: 500px;
  border: black solid 1px;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.react-modal_title {

}

.react-modal_close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.react-modal_content {

}

.react-modal_buttons {
  padding-top: 10px;
  display: flex;

  & > * {
    margin: 0 5px;
  }
}

.react-modal_submit {

}

.react-modal_submit_secondary {
  margin-left: 5px;
}

.react-modal_submit_reset {
  margin-top: 5px;
  margin-right: 5px;
}