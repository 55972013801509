@use "sass:math";

$width: 1400px;
$columns: 36;
$rows: 37;

.character-module-builder {

  font-family: Anonymous_Pro,Lucida Sans Typewriter,Lucida Typewriter,Lucida Console,monospace;

  .table>:not(:first-child) {
    border: none;
  }

  .cell {
    height: 26px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    text-align: center;
  }

  $col-1: math.div($width, $columns);

  @for $i from 1 through ($columns - 1) {
    .colSpan-#{$i} {
      width: $col-1 * $i;
    }
  }

  @for $i from 1 through ($columns - 1) {
    .colSpan-floor-#{$i} {
      width: floor($col-1 * $i);
    }
  }

  .colSpan-36 {
    width: $width;
  }

  .table {
    border-top: 1px solid black;
    border-right: 1px solid black;
    width: $width;
  }

  .cell-inverted {
    background-color: black;
    color: white;
  }

  //.grid {
  //  display: grid;
  //  grid-template-columns: repeat($columns, 38px);
  //  grid-template-rows: repeat($rows, 26px);
  //  grid-gap: 1px;
  //}

}

.sheet-builder-variables {

  margin-bottom: 25px;

  table {
    width: 100%;
    max-width: 1000px;
  }

  th {
    font-weight: 100;
    font-size: 18px;
  }
}

.sheet-builder-variables__new {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.sheet-builder-variables__row__item {
  width: 50%
}

.sheet-builder-variables__row__pad {
  margin-right: 5px;
}

.editor-row {
  display: flex;
}

.grid-editor-modal__label {
  margin-right: 5px;
}

.grid-editor-modal__input {
  width: 100%;
}

.grid-editor-modal__input__wrapper {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.grid-editor-modal__input__wrapper label {
  margin: 0 5px 0 0;
}

.grid-editor-modal__input__type-selector {
  width: 100%;
}

.character-module-builder .cell-input {
  padding: 0;
  width: 100%;
  text-align: left;
  background-color: rgba(0, 0, 0, .06);
  border: none;
}

.character-module-builder .cell-text {
  padding: 0;
  width: 100%;
  position: center;
}

.character-module-builder .cell-inverted, .character-module-builder .cell-inverted input {
  color: white;
}

.character-module-builder .cell-standard, .character-module-builder .cell-standard input {
  color: black;
}
