@use "sass:math";
//update to allow portrait and landscape

$width: 1400px;

@font-face {
  font-family: 'Anonymous_Pro';
  src: url('/font/Anonymous_Pro.woff2') format('woff2'),
  url('/font/Anonymous_Pro.woff') format('woff'),
  url('/font/Anonymous_Pro.tff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.Character .react-tabs__tab-list {
  text-align: center;
}

.characterSheet__table {
  font-family: Anonymous_Pro, Lucida Sans Typewriter, Lucida Typewriter, Lucida Console, monospace;
  width: $width;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid black;
  border-right: 1px solid black;
  margin: auto;

  .table__header {
    background-color: black;
    color: white;
    text-align: center;
    height: 21px;
  }

  .cell {
    height: 26px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  .caption {
    text-align: center;
    line-height: 26px;
  }

  $col-1: math.div($width, 36);

  @for $i from 1 through 35 {
    .colSpan-#{$i} {
      width: $col-1 * $i;
    }
  }

  @for $i from 1 through 35 {
    .colSpan-floor-#{$i} {
      width: floor($col-1 * $i);
    }
  }

  .colSpan-36 {
    width: $width;
  }
}

.characterSheet__options {
  margin: auto;
  max-width: 1000px;
}

.submit-form {
  max-width: 1000px;
  margin: auto;
}

.hours_form {
  display: flex;
}

.button-create-wrapper {
  margin: auto;
  max-width: 1000px;
}