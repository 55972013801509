.module-selector {
  padding: 10px 0;
}

.module-selector__selector {
  display: flex;
  width: 100%;
}

.module-selector__selector__selector {
  width: 100%;
}

