.characterSheetView {
    width: 1400px;
    border-spacing: 0;
    border-collapse: collapse;

    position: absolute;
    top: 0;

    @media print {
        -webkit-print-color-adjust: exact;
    }

}

.seaxeCharacterSheet {
    margin-bottom: 75px;
}