//globals
@import '~bootstrap/scss/bootstrap';
@import 'base/bootstrap.overrides';

@import 'global';
//external
@import '~react-toggle/style.css';
@import '~react-tabs/style/react-tabs.scss';
@import '~react-toastify/dist/ReactToastify.css';

//base container
@import 'base/container';

//features
@import '../src/features/character-view/characterView.scss';
@import '../src/features/character-editor/characterEditor.scss';
@import '../src/features/search/search.scss';
@import '../src/features/settings/settings.scss';
@import '../src/features/user/user.scss';
@import '../src/features/users/users.scss';
@import '../src/features/tag/tags.scss';
@import '../src/features/roles/roles.scss';
@import '../src/features/spellbook/styles.scss';
@import '../src/features/sheet-module-list/styles.scss';
@import '../src/features/sheet-module-builder/styles.scss';

//components
@import '../src/components/pagination/pagination.scss';
@import '../src/components/tagPanel/tag.scss';
@import '../src/components/modal/styles.scss';
@import '../src/components/module-selector/styles.scss';
@import '../src/components/module-order/styles.scss';
