.settings {
  .react-tabs {
    @media (min-width: $tablet-width) {
      display: flex;
    }
    display: block;
    margin-top: 30px;
  }

  .react-tabs__tab-list {
    width: 250px;
    border-bottom: none;
    padding: inherit;
    margin: auto;
    @media (min-width: $tablet-width) {
      padding: 0;
      margin: initial;
    }
  }

  .react-tabs__tab {
    width: 250px;
    font-weight: bold;
    border-radius: 5px 5px;
    border: 1px solid #aaa;
    margin: 5px 0;
    &.react-tabs__tab--selected {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
  }
  .react-tabs__tab:focus:after {
    height: 0;
  }

  .react-tabs__tab-panel {
    padding-left: 0;
    width: 100%;
    @media (min-width: $tablet-width) {
      padding-left: 35px;
    }
    .react-tabs__tab-panel--selected {
    }
  }

  .form-control.notValid {
    background-color: #fff;
    border-color: #ff3865;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(247, 7, 7, 0.44);
  }

}







