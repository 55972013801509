.user-navigation {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3b3b3b;

  .nav-btn {
    padding: 0 10px;
    font-size: 20px;
    height: 47px;
    display: flex;
    align-items: center;
    color: #f5f5f5;
    border-bottom: 6px solid #3b3b3b;
    border-top: 6px solid #3b3b3b;

    &.active {
      border-bottom: 6px solid #a61f21;
    }

    &:hover {
      border-bottom: 6px solid #a61f21;
      text-decoration: none;
    }
  }
}

.permission_select {
  width: 200px;
}
