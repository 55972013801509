.search__controls {
  .Select {
    width: 100%;
  }

  .input-group {
    flex-wrap: nowrap;
  }
}

.react-select-container, .tag-select {
  width: 100%;
}

.search__controls-field {
  padding: 5px 0;
  display: flex;
  width: 100%;

  @media (min-width: $tablet-width) {
    &.start {
      padding-right: 5px;
    }
    &.end {
      padding-left: 5px;
    }
  }

  .Select {
    width: 100%;
  }

  .Select-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.search__filter-wrapper {
  @media (min-width: $tablet-width) {
    display: flex;
  }
}

.tag-select .Select-control {
  border-radius: 0;
}

.search__type {
  display: flex;
  width: 100%;
}

$bg: #e9ecef82;
$border: #d9d9d9;
$selected: #6f7172;

.search__type__button {
  width: 100%;
  border: 1px solid $border;
  border-left: 0;
  border-right: 0;
  padding: 0;
  cursor: pointer;
  background-color: $bg;

  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    background-color: $selected;
    border: 1px solid $selected;
    border-left: 0;
    border-right: 0;
  }

  &.selected {
    color: white;
    background-color: $selected;
    border: 1px solid $selected;
    border-left: 0;
    border-right: 0;
  }

  &.end {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid #d9d9d9;
  }
}

.tag-select-button {
  width: 30px;
  border: 1px solid #d9d9d9;
  padding: 0;
  cursor: pointer;
  background-color: #e9ecef82;

  &.first {
    border-left: 0;
    border-right: 0;
  }

  &.selected {
    color: #fff;
    background-color: #6f7172;
    border: 1px solid #6f7172;
  }
}

.search-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.search-sort_button_wrapper {
  display: flex;
}

.search-sort_button {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  text-decoration: none;
  margin: 0 2px;

  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    text-decoration: none;
  }

  i {
    padding-left: 5px;
  }
}