#page-content-wrapper {
  position: relative;
  margin: auto;
}

.main-wrapper {
  padding: 10px 20px 20px;
}

@media print {
  @page {
    size: auto;   /* auto is the initial value */
    margin: 15px 30px;  /* this affects the margin in the printer settings */
  }

  html {
    margin: 0; /* this affects the margin on the html before sending to printer */
  }
}