@import './roleEditor/styles';

.roles-list_header {
  display: flex;
  align-items: center;
}

.roles-list_title {
  padding-right: 10px;
}

.permissions_table {
  width: 100%;
  border: solid 2px black;
  margin: 5px 0;

  td {
    border: solid 1px dimgrey;

  }
}

.permission_thead {
  td:first-child {
    width: 150px;
  }

  td {
    text-align: center;
    background-color: lightgrey;
  }
}

.permission_tbody {
  td:first-child {
    width: 150px;
    background-color: lightgrey;
  }

  td {
    text-align: center;
  }
}

.roles_view {
  td {
    text-align: center;
  }
}

