@font-face {
  font-family: 'Chlanou';
  src: url('/font/Chlanou.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.spellbook-wrapper {
  display: flex;
}

.spell-output {
  font-family: 'Chlanou';
  writing-mode: vertical-rl;
  text-orientation: upright;
  height: 500px;
}

.spell-editor {
  display: flex;
}

.spell-input {
  display: flex;
  flex-direction: column;
}


@media print {
  .spell-editor, .spell-editor * {
    display: none !important;
  }
}