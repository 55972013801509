.tags {
  min-height: 500px;
  max-width: 1000px;
  margin: auto;
}

.tags-search-create {
  position: relative;
  z-index: 2;
}

.tsc_select {
  position: absolute;
  left: 0;
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  width: 100%;
  overflow-y: scroll;
  display: none;
  button {
    text-align: left;
    background-color: white;
    border: none;
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 126, 255, 0.08);
    }
  }
}

.showDropdown .tsc_select {
  display: block
}

.tsc_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
}

.tsc_overlay.showDropdown {
  display: block;
}
