.user-tag-list {
  display: flex;
  flex-wrap: wrap;
}

.tag-pod {
  display: inline-block;
  border: 1px solid #6c757d;
  padding: 0 0 0 5px;
  border-radius: 45px;
  border-right: 0;
  margin: 10px;
}

.tag-pod__container {
  display: flex;
}

.tag-pod__name {
  padding: 0 10px;
}

.tag-pod__button {
  padding: 0 9px;
  margin: 0;
  border: 1px solid #6c757d;
  border-radius: 55px;
  cursor: pointer;
  background: #6c757d;
  color: white;
  line-height: 15px;
}